/*==============================================================================
// File:        submissions.js
// Package:     Ruby on Rails / Mr Zen
// Synopsis:    Form Submission Controls 
//============================================================================*/

document.addEventListener("DOMContentLoaded", function() {
  // Global variables.
  //
  var gloVar = {
    adError: "error",
    invalid: "invalid",
    zenError: "zen-error",
    isRequired: "is-required",
    zenRequire: "zen-required",
    zenCheckbx: ".zen-input--checkbox",
    subSection: ".zen-submission__section",
    // Standard JS selectors.
    //
    _body: document.getElementById("body"),
    _masked: document.querySelectorAll(".masked"),
    _stageOne: document.getElementById("stage-1"),
    _stageTwo: document.getElementById("stage-2"),
    _heroImage: document.getElementById("hero-image"),
    _productLt: document.getElementById("product-list"),
    _setDetails: document.getElementById("set-details"),
    _toStageTwoBtn: document.getElementById("goto-stage2"),
    _stageTwoSubmit: document.getElementById("stage-2-submit"),
    _formSubmission: document.getElementById("new_submission"),
    _subProduct: document.getElementById("submission_product_id"),
    _subCountry: document.getElementById("submission_country"),
    _newsSignup: document.getElementById("newsletter-signup"),
    _newsSignupBtn: document.getElementById("newsletter-signup-btn"),
    _cashbackTotal: document.getElementById('cashbackTotal'),
  };

  if (gloVar._stageTwo !== null) {
    var defRequireMs = gloVar._stageTwo.dataset.required_text,
        chkRequireMs = gloVar._stageTwo.dataset.required_checkbox_text;
  }


  function sectionCheckboxes(section, target) {
    var _checkbox = section.querySelectorAll(gloVar.zenCheckbx);
    Array.prototype.forEach.call(_checkbox, function(input, response) {
       var _parent = input.parentNode;
       response = document.createElement("span");
       response.className = gloVar.zenError;
       response.innerHTML = chkRequireMs;
      if (input.checked === true) {
        removeClass(_parent, gloVar.isRequired);
        var required = _parent.querySelector("." + gloVar.zenError);
        if (required !== null) {
          required.parentNode.removeChild(required);
        }
      }
      else {
        if (!hasClass(_parent, gloVar.isRequired)) {
          addClass(_parent, gloVar.isRequired);
          _parent.appendChild(response);
        }
      }
    });
  }


  if (gloVar._toStageTwoBtn !== null) {
    gloVar._toStageTwoBtn.addEventListener("click", function(element) {
      sectionCheckboxes(gloVar._stageOne);
      var _errFound = gloVar._stageOne.querySelectorAll("." + gloVar.zenError);
      if (_errFound.length > 0) {
        removeClass(gloVar._stageOne, "d-none");
        removeClass(gloVar._heroImage, "d-none");
        removeClass(gloVar._setDetails, "active");
        removeClass(gloVar._productLt, "d-none");
        removeClass(gloVar._stageTwo, "d-block");
        if (!hasClass(gloVar._stageTwo, "d-none")) {
          addClass(gloVar._stageTwo, "d-none");
        }
      }
      else {
        removeClass(gloVar._stageOne, "d-block");
        removeClass(gloVar._heroImage, "d-block");
        removeClass(gloVar._productLt, "d-block");
        removeClass(gloVar._stageTwo, "d-none");
        if (!hasClass(gloVar._setDetails, "active") || !hasClass(gloVar._stageOne, "d-none") || !hasClass(gloVar._heroImage, "d-none") || !hasClass(gloVar._productLt, "d-none")) {
          addClass(gloVar._stageOne, "d-none");
          addClass(gloVar._heroImage, "d-none");
          addClass(gloVar._productLt, "d-none");
          addClass(gloVar._setDetails, "active");
        }
        gloVar._body.scrollIntoView({
          behavior: "smooth"
        });
      }
    });
  }

  /*------------------------------------------------------------------------------
  // Validation - Stage 2
  //----------------------------------------------------------------------------*/
 

  if (gloVar._subCountry !== null || gloVar._subProduct !== null) {
    // Country select.
    //
    gloVar._subCountry.addEventListener("change", function(select) {
      var _countrySelVal = gloVar._subCountry.options[gloVar._subCountry.selectedIndex],
          _parent = gloVar._subCountry.parentNode;
      if (_countrySelVal.value.length > 0) {
        removeClass(gloVar._subCountry, gloVar.invalid);
        removeClass(gloVar._subCountry.parentNode, gloVar.isRequired);
        var _invalidContainer = _parent.querySelector("." + gloVar.zenError);
        if (_invalidContainer !== null) {
          _invalidContainer.parentNode.removeChild(_invalidContainer);
        }
        if (gloVar._subCountry.closest(gloVar.subSection).querySelectorAll("." + gloVar.invalid).length === 0) {
          removeClass(gloVar._subCountry.closest(gloVar.subSection), gloVar.adError);
        }
      }
      else {
        if (!hasClass(gloVar._subCountry, gloVar.invalid)) {
          addClass(gloVar._subCountry, gloVar.invalid);
        }
      }
    });
    // Device select.
    //
    gloVar._subProduct.addEventListener("change", function(select) {
      var _deviceSelVal = gloVar._subProduct.options[gloVar._subProduct.selectedIndex],
          _parent = gloVar._subProduct.parentNode;
      if (_deviceSelVal.value.length > 0) {
        removeClass(gloVar._subProduct, gloVar.invalid);
        removeClass(gloVar._subProduct.parentNode, gloVar.isRequired);
        var _invalidContainer = _parent.querySelector("." + gloVar.zenError);
        if (_invalidContainer !== null) {
          _invalidContainer.parentNode.removeChild(_invalidContainer);
        }
        if (gloVar._subProduct.closest(gloVar.subSection).querySelectorAll("." + gloVar.invalid).length === 0) {
          removeClass(gloVar._subProduct.closest(gloVar.subSection), gloVar.adError);
        }
      }
      else {
        if (!hasClass(gloVar._subProduct, gloVar.invalid)) {
          addClass(gloVar._subProduct, gloVar.invalid);
        }
      }
    });
  }

  if (gloVar._stageTwo !== null) {
    var _s2Reqfields = gloVar._stageTwo.querySelectorAll("." + gloVar.zenRequire);
    Array.prototype.forEach.call(_s2Reqfields, function(field) {
      var _parent = field.parentNode;
      if (field.value.length > 0) {
        removeClass(field, gloVar.invalid);
        removeClass(_parent, gloVar.isRequired);
      }
      field.addEventListener("keyup", function() {
        if (field.value.length > 0) {
          removeClass(field, gloVar.invalid);
          removeClass(_parent, gloVar.isRequired);
          var _invalidContainer = _parent.querySelector("." + gloVar.zenError);
          if (_invalidContainer !== null) {
            _invalidContainer.parentNode.removeChild(_invalidContainer);
          }
          if (field.closest(gloVar.subSection).querySelectorAll("." + gloVar.invalid).length === 0) {
            removeClass(field.closest(gloVar.subSection), gloVar.adError);
          }
        }
        else {
          if (!hasClass(field, gloVar.invalid)) {
            addClass(field, gloVar.invalid);
          }
        }
      });
    });
  }
  if (gloVar._formSubmission !== null) {
    // Prevent form validation.
    //
    gloVar._formSubmission.setAttribute("novalidate", true);
    // Manage form submission.
    //
    gloVar._stageTwoSubmit.addEventListener("click", function(element) {
      var _s2invfields = document.querySelectorAll("." + gloVar.invalid);
      if (_s2invfields.length > 0) {
        element.preventDefault();
        element.stopPropagation();
        Array.prototype.forEach.call(_s2invfields, function(field, response) {
          var _parent = field.parentNode;
          response = document.createElement("span");
          response.className = gloVar.zenError;
          response.innerHTML = defRequireMs;
          if (!hasClass(_parent, gloVar.isRequired)) {
            addClass(_parent, gloVar.isRequired);
            _parent.appendChild(response);
          }
          if (!hasClass(field.closest(gloVar.subSection), gloVar.adError)) {
            addClass(field.closest(gloVar.subSection), gloVar.adError);
          }
          document.querySelector("." + gloVar.adError).scrollIntoView({
            behavior: "smooth"
          });
        });
      }
      else {
        // Reset form validation.
        //
        gloVar._formSubmission.removeAttribute("novalidate");
        setTimeout(function(){ 
          gloVar._formSubmission.submit(); 
          gloVar._body.scrollIntoView({
            behavior: "smooth"
          });
        }, 200);
      }
    });
  }

  if (gloVar._masked !== null) { 
    Array.prototype.forEach.call(gloVar._masked, function(mask) {
      mask.dispatchEvent(new Event("keyup"));
    });
  }

  if (document.querySelector("." + gloVar.adError)) {
    document.querySelector("." + gloVar.adError).scrollIntoView({
      behavior: "smooth"
    });
  }

  /*------------------------------------------------------------------------------
  // Newletter Sign-up - Stage 3
  //----------------------------------------------------------------------------*/

  if (gloVar._newsSignup !== null) {
    validateSignupButton(gloVar._newsSignup, gloVar._newsSignupBtn);
    gloVar._newsSignup.addEventListener("change", function() {
      validateSignupButton(gloVar._newsSignup, gloVar._newsSignupBtn);
    });
  }
});

/*------------------------------------------------------------------------------
// Class Management & Other Functions
//----------------------------------------------------------------------------*/

function validateSignupButton(target, btn) {
  if (target.checked === true) {
    btn.removeAttribute("disabled");
  } 
  else {
    setAttributes(btn, {
      "disabled": "disabled"
    });
  }
}

function setAttributes(el, attrs) {
  for(var key in attrs) {
    if(attrs.hasOwnProperty(key)) {
      el.setAttribute(key, attrs[key]);
    }
  }
}

var hasClass = function (el, cl) {
  var regex = new RegExp("(?:\\s|^)" + cl + "(?:\\s|$)");
  return !!el.className.match(regex);
},
addClass = function (el, cl) {
  el.className += " " + cl;
},
removeClass = function (el, cl) {
  var regex = new RegExp("(?:\\s|^)" + cl + "(?:\\s|$)");
  el.className = el.className.replace(regex, " ");
},
toggleClass = function (el, cl) {
  return hasClass(el, cl) ? removeClass(el, cl) : addClass(el, cl);
};
  
/*------------------------------------------------------------------------------
// Polyfill 
//----------------------------------------------------------------------------*/

if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.msMatchesSelector || 
    Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    var el = this;

    do {
      if (Element.prototype.matches.call(el, s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}
